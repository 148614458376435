<template>
  <div id="view-setting">
    <!-- 上方：標題 -->
    <Topbar :backTo="{ name: 'Network' }">業務課 網段設定</Topbar>
    <div class="content-container">
      <!-- 左側：次導覽列 -->
      <SideNav>
        <router-link
          class="side-nav-link"
          :to="{ name: 'RangeSetting', params: $route.params.id }"
          >管理範圍設定</router-link
        >
        <router-link
          class="side-nav-link router-link-active"
          :to="{ name: 'GeneralSetting', params: $route.params.id }"
          >屬性設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'DHCPDNSSetting', params: $route.params.id }"
          >DHCP DNS 設定</router-link
        >
      </SideNav>

      <div class="setting-container">
        <div class="title-row">
          <h3>屬性設定</h3>
          <button class="btn form-btn-outline mr-2">
            編輯
          </button>
        </div>
        <div>
          <div class="feild-row">
            <p class="feild-name">VLAN ID</p>
            <p class="feild-value">67</p>
          </div>
          <div class="feild-row">
            <p class="feild-name">名稱</p>
            <p class="feild-value">Sales Department</p>
          </div>
          <div class="feild-row">
            <p class="feild-name">群組</p>
            <p class="feild-value">-</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 隱藏：Dialog 容器 -->
  <div class="dialog-container"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
// import '@progress/kendo-ui/js/kendo.dialog'
// import { Dialog } from '@progress/kendo-vue-dialogs'
// import useToggle from '@/composable/useToggle'

export default defineComponent({
  components: {
    Topbar,
    SideNav
  },
  setup() {
    return {}
  }
})
</script>

<style scoped lang="scss"></style>
